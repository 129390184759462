import * as _ from 'lodash/fp';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { WidgetLibrary } from '../select-cell-type-dialog.types';
import { SelectCellTypeDialogService } from '../select-cell-type-dialog.service';

@Component({
    selector: 'udp-select-type',
    templateUrl: './select-type.component.html',
    styleUrls: ['./select-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTypeComponent {

    @Output()
    widgetCategorySelect: EventEmitter<string> = new EventEmitter();

    @Output()
    nextClick: EventEmitter<void> = new EventEmitter();

    widgetGroups: WidgetLibrary;

    selectedWidgetGroupIdx: number;
    selectedWidgetIdx: number;

    get isAnyWidgetSelected(): boolean {
        return !_.isNil(this.selectedWidgetIdx);
    }

    constructor(
        dialogService: SelectCellTypeDialogService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.widgetGroups = dialogService.getWidgetLibrary();
    }

    selectWidget(groupIdx: number, widgetIdx: number) {
        this.selectedWidgetGroupIdx = groupIdx;
        this.selectedWidgetIdx = widgetIdx;

        this.emitSelectEvent();
        this.changeDetector.markForCheck();
    }

    isWidgetSelected(groupIdx: number, widgetIdx: number) {
        return groupIdx === this.selectedWidgetGroupIdx
            && widgetIdx === this.selectedWidgetIdx;
    }

    private emitSelectEvent() {
        const selectedGroup = this.widgetGroups[this.selectedWidgetGroupIdx];
        const selectedWidget = selectedGroup.elements[this.selectedWidgetIdx];
        this.widgetCategorySelect.emit(selectedWidget.category);
    }

}
