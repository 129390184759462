import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarMessageComponent, SnackbarMessageType } from './component/snackbar-message.component';


@Injectable()
export class SnackbarService {
    constructor(
        private snackBar: MatSnackBar,
    ) { }

    openMessage(
        message: string,
        type: SnackbarMessageType = 'info',
        options?: Partial<MatSnackBarConfig>
    ) {
        const { instance } = this.snackBar.openFromComponent(
            SnackbarMessageComponent, {
                duration: 3000,
                ...options,
            }
        );

        instance.message = message;
        instance.type = type;
    }
}

