import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { EntityTextIndicatorWrapperComponent } from './wrappers/entity-text-indicator/entity-text-indicator-wrapper.component';
import { HorizontalBarsIndicatorWrapperComponent } from './wrappers/horizontal-bars-indicator/horizontal-bars-indicator-wrapper.component';
import { BarChartWrapperComponent } from './wrappers/bar-chart/bar-chart-wrapper.component';
import { LogsListWrapperComponent } from './wrappers/logs-list/logs-list-wrapper.component';

import { HorizontalBarsIndicatorModule } from '../horizontal-bars-indicator/horizontal-bars-indicator.module';
import { EntityTextIndicatorModule } from '../entity-text-indicator/entity-text-indicator.module';
import { WidgetWrapperService } from './wrappers/widget-wrapper.service';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { BarChartModule } from '../bar-chart/bar-chart.module';
import { LogsListModule } from '../logs-list/logs-list.module';
import { getIconRegistry } from '../../utils/iconRegistry';
import { WidgetComponent } from './base/widget.component';

const featherIcons = [
    'trash'
];

const imports = [
    CommonModule,
    EntityTextIndicatorModule,
    IconButtonModule,
    HorizontalBarsIndicatorModule,
    BarChartModule,
    LogsListModule
];

const providers = [
    WidgetWrapperService
];

const components = [
    WidgetComponent
];

const entryComponents = [
    EntityTextIndicatorWrapperComponent,
    HorizontalBarsIndicatorWrapperComponent,
    BarChartWrapperComponent,
    LogsListWrapperComponent
];

@NgModule({
    imports,
    providers,
    entryComponents: entryComponents,
    declarations: [
        ...components,
        ...entryComponents
    ],
    exports: components
})
export class WidgetModule {

    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
    ) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace(
            'feather',
            iconsPath
        )(featherIcons);
    }

}
