import { BarChartOptions } from './bar-chart.types';

export const BAR_CHART_DEFAULT_OPTIONS: BarChartOptions = {
    xAxis: {
        border: '#92A9C0',
        color: '#92A9C0',
        minorGridLines: {
            visible: false,
        },
        majorGridLines: {
            visible: false,
        },
        visible: true,
    },
    yAxis: {
        border: 'transparent',
        color: '#92A9C0',
        minorGridLines: {
            visible: false,
        },
        majorGridLines: {
            visible: true,
            color: '#CDDCE6',
        },
        visible: true,
    },
};
