import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconButtonModule } from './../icon-button/icon-button.module';
import { IconModule } from './../icon/icon.module';
import { AddButtonComponent } from './add-button/add-button.component';
import { AutocompleteOptionComponent } from './autocomplete-option/autocomplete-option.component';
import { CheckboxInputComponent } from './checkbox/checkbox-input.component';
import { DatetimeComponent } from './datetime/datetime.component';
import { DictionaryComponent } from './dictionary/dictionary.component';
import { FormFieldComponent } from './field/form-field.component';
import { LabelComponent } from './label/label.component';
import { ListComponent } from './list/list.component';
import { MultiAutocompleteComponent } from './multi-autocomplete/multi-autocomplete.component';
import { SearchBoxComponent } from './search-box/search-box.component';

const imports = [
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    IconModule,
    IconButtonModule,
    MatChipsModule,
    ScrollingModule,
];

const components = [
    CheckboxInputComponent,
    ListComponent,
    LabelComponent,
    DictionaryComponent,
    AddButtonComponent,
    AutocompleteOptionComponent,
    MultiAutocompleteComponent,
    DatetimeComponent,
];

const exportedComponents = [FormFieldComponent, SearchBoxComponent];

@NgModule({
    imports: [imports, MatAutocompleteModule, MatIconModule],
    declarations: [...components, ...exportedComponents],
    exports: [...exportedComponents],
})
export class FormFieldModule {}
