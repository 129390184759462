import { BehaviorSubject, Observable } from 'rxjs';

import { insertEmptyRowWithSizes } from './utils/insertEmptyRowWithSizes';
import { GridLayoutModel } from '../grid-layout/grid-layout.types';
import { WidgetModel } from '../widget/widget.types';
import { setCellData } from './utils/setCellData';
import { removeCell } from './utils/removeCell';
import { removeRowAtIndex } from './utils/removeRowAtIndex';

export abstract class WidgetDashboardService {

    private layout: BehaviorSubject<GridLayoutModel<WidgetModel>>
        = new BehaviorSubject(null);

    abstract fetchLayout(): Observable<GridLayoutModel<WidgetModel>>;
    abstract saveLayout(layout: GridLayoutModel<WidgetModel>): void;
    abstract showInsertCellContentDialog(): Observable<WidgetModel>;
    abstract showInsertRowDialog(): Observable<number[]>;

    getLayout(): Observable<GridLayoutModel<WidgetModel>> {
        if (!this.layout.getValue()) {
            this.fetchLayout()
                .subscribe((layout) => {
                    this.layout.next(layout);
                });
        }

        return this.layout;
    }

    onNewCellButtonClick(rowIndex: number, cellIndex: number) {
        this.showInsertCellContentDialog()
            .subscribe((widgetModel) => {
                const nextLayout = setCellData(
                    this.layout.getValue(),
                    rowIndex,
                    cellIndex,
                    widgetModel
                );
                this.updateLayout(nextLayout);
            });
    }

    onRemoveCellClick(rowIndex: number, cellIndex: number) {
        const nextLayout = removeCell(
            this.layout.getValue(),
            rowIndex,
            cellIndex
        );
        this.updateLayout(nextLayout);
    }

    onNewRowButtonClick() {
        this.showInsertRowDialog()
            .subscribe((sizes) => {
                const nextLayout = insertEmptyRowWithSizes(
                    this.layout.getValue(),
                    sizes
                );
                this.updateLayout(nextLayout);
            });
    }

    onRemoveRowClick(rowIndex: number) {
        const nextLayout = removeRowAtIndex(
            this.layout.getValue(),
            rowIndex
        );
        this.updateLayout(nextLayout);
    }

    private updateLayout(nextLayout: GridLayoutModel<WidgetModel>) {
        this.layout.next(nextLayout);
        this.saveLayout(nextLayout);
    }

}
