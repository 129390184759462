import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByProperty',
})
export class FilterByPropertyPipe implements PipeTransform {
    transform(data: any[], filter?: any): any {
        if (!filter || Object.entries(filter).length === 0) {
            return data;
        }

        const [[property, value]] = Object.entries(filter);

        return data.filter(item => {
            if (item.hasOwnProperty(property)) {
                return item[property].includes(value);
            }
            return false;
        });
    }
}
