import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { SlideToggleSize, SlideToggleDirection } from './slide-toggle.types';

@Component({
    selector: 'udp-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SlideToggleComponent {

    @Input()
    size: SlideToggleSize = 'default';

    @Input()
    direction: SlideToggleDirection = 'default';

    @Input()
    label: string;

    @Input()
    value: boolean;

    @Input()
    disabled: boolean;

    @Output()
    valueChange: EventEmitter<boolean> = new EventEmitter();

    onChange(e: MatSlideToggleChange) {
        this.valueChange.emit(e.checked);
    }

}
