import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from 'lodash/fp';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { showHideClearButton } from '../form-field.animation';
import { InputType, Option } from '../form-field.types';
import { IconVariant } from './../../../types/icons';


const NON_TEXT_FIELDS: InputType[] = ['checkbox', 'list', 'dictionary'];

@Component({
    selector: 'udp-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [showHideClearButton],
})
export class FormFieldComponent {
    @Input()
    type: InputType = 'text';

    @Input()
    options: Option[] = [];

    @Input()
    placeholder = '';

    @Input()
    label: string;

    @Input()
    suffixIcon: string;

    @Input()
    suffixIconVariant: IconVariant = 'material';

    @Input()
    set control(control: FormControl) {
        this._control = control;
        this.onControlChange$.next();
    }
    get control(): FormControl {
        return this._control;
    }
    private _control: FormControl = new FormControl();

    @Input()
    set defaultValue(defaultValue: any) {
        this._defaultValue = defaultValue;
        if (!this.control.value) {
            this.control.setValue(defaultValue);
        }
    }
    private _defaultValue: any;

    @Input()
    required = false;

    @Input()
    tooltipMessage: string;

    @Input()
    clearEnabled: boolean;

    @Output()
    optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

    @Output()
    valueChanges = new EventEmitter<any>();

    private onControlChange$: Subject<void> = new Subject();

    constructor(public elementRef: ElementRef) {
        this.onControlChange$.subscribe(() => {
            this.control.valueChanges
                .pipe(takeUntil(this.onControlChange$))
                .subscribe(() => this.valueChanges.emit(this.control.value));
        });
        this.onControlChange$.next();
    }

    displayAutocompleteValue = _.get('name');

    isSimpleTextField = (type: InputType) => !_.includes(type, NON_TEXT_FIELDS);

    trackByOptions = (idx: number, { name }: Option) => name;

    onClearClicked() {
        this.control.setValue(null);
    }
}
