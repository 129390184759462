import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, Optional } from '@angular/core';

import { GridLayoutModel } from '../../grid-layout/grid-layout.types';
import { WidgetDashboardService } from '../widget-dashboard.service';
import { WidgetModel } from '../../widget/widget.types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'udp-widget-dashboard',
    templateUrl: './widget-dashboard.component.html',
    styleUrls: ['./widget-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDashboardComponent implements OnDestroy {

    layout: GridLayoutModel<WidgetModel>;

    private onDestroy$: Subject<void> = new Subject();

    constructor(
        @Optional() private service: WidgetDashboardService,
        private changeDetector: ChangeDetectorRef
    ) {
        if (!service) {
            throw new Error(
                'WidgetDashboardComponent requires WidgetDashboardService '
                + 'provider to be declared.'
            );
        }

        service.getLayout()
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((layout) => {
                this.layout = layout;
                this.changeDetector.markForCheck();
            });
    }

    onNewCellButtonClick(rowIndex: number, cellIndex: number) {
        this.service.onNewCellButtonClick(rowIndex, cellIndex);
    }

    onRemoveCellClick(rowIndex: number, cellIndex: number) {
        this.service.onRemoveCellClick(rowIndex, cellIndex);
    }

    onNewRowButtonClick() {
        this.service.onNewRowButtonClick();
    }

    onRemoveRowClick(rowIndex: number) {
        this.service.onRemoveRowClick(rowIndex);
    }

    trackByIdx(index: number) {
        return index;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
