import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@xfusiontech/shared';
import { Observable } from 'rxjs';
import { resolve as resolveUrl } from 'url';
import { environment } from '../environments/environment';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(this.handleRequest(request));
    }

    private handleRequest(baseRequest: HttpRequest<any>) {
        const url = baseRequest.url;
        const accessToken = this.authService.getAccessToken();
        const headers = baseRequest.headers.set('accessToken', accessToken);

        return baseRequest.clone(accessToken ? { url, headers } : { url });
    }
}
