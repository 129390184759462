import { ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';

import { EntityTextIndicatorWrapperComponent } from './entity-text-indicator/entity-text-indicator-wrapper.component';
import { HorizontalBarsIndicatorWrapperComponent } from './horizontal-bars-indicator/horizontal-bars-indicator-wrapper.component';
import { BarChartWrapperComponent } from './bar-chart/bar-chart-wrapper.component';
import { LogsListWrapperComponent } from './logs-list/logs-list-wrapper.component';

import { WidgetWrapperComponent } from './widget-wrapper.component';
import { WidgetCategory, WidgetData } from '../widget.types';

const categoryComponentMap: Record<
    WidgetCategory,
    Type<WidgetWrapperComponent<any>>
> = {
    'entity-text': EntityTextIndicatorWrapperComponent,
    'horizontal-bars': HorizontalBarsIndicatorWrapperComponent,
    'bar-chart': BarChartWrapperComponent,
    'logs-list': LogsListWrapperComponent
};

@Injectable()
export class WidgetWrapperService {

    constructor(
        private factoryResolver: ComponentFactoryResolver
    ) {}

    getComponentFactory(
        category: string
    ): ComponentFactory<WidgetWrapperComponent<WidgetData>> {
        const componentType = categoryComponentMap[category];
        if (!componentType) {
            throw new Error(
                `No widget wrapper found for category "${category}"`
            );
        }

        const factory = this.factoryResolver.resolveComponentFactory
            <WidgetWrapperComponent<WidgetData>>(componentType);
        return factory;
    }

}
