import {
    ExpansionHeaderDirective,
    ExpandedContentDirective,
    ExpansionPanelComponent,
} from './expansion-panel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonModule } from '../icon-button/icon-button.module';

const components = [ExpansionPanelComponent];
const directives = [ExpandedContentDirective, ExpansionHeaderDirective];

@NgModule({
    declarations: [...components, ...directives],
    imports: [CommonModule, IconButtonModule],
    exports: [...components, ...directives],
})
export class ExpansionPanelModule {}
