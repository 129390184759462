import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'udp-select-cell-type-dialog',
    templateUrl: './select-cell-type-dialog.component.html',
    styleUrls: ['./select-cell-type-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectCellTypeDialogComponent {

    step = 0;
    selectedCategory: string;
    title: string;
    source: string;

    constructor(
        private dialogRef: MatDialogRef<SelectCellTypeDialogComponent>
    ) { }

    onWidgetCategorySelected(category: string) {
        this.selectedCategory = category;
    }

    onNextButtonClick() {
        this.step += 1;
    }

    onBackButtonClick() {
        this.step -= 1;
    }

    onContentModelChange(next: any) {
        const { title, source } = next;
        this.title = title;
        this.source = source;
    }

    onSaveClick() {
        this.dialogRef.close({
            selectedCategory: this.selectedCategory,
            title: this.title,
            source: this.source
        });
    }

}
