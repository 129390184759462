import { Injectable } from '@angular/core';
import { WIDGET_LIBRARY } from './select-cell-type-dialog.consts';
import { WidgetLibrary } from './select-cell-type-dialog.types';

@Injectable()
export class SelectCellTypeDialogService {

    getWidgetLibrary(): WidgetLibrary {
        return [...WIDGET_LIBRARY];
    }

}
