import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'udp-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckboxInputComponent {

    @Input()
    control: FormControl = new FormControl();

    @Input()
    tooltipMessage: string;

    @Input()
    label = '';
}
