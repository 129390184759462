import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatRippleModule } from '@angular/material';

import { NewElementButtonComponent } from './new-element-button/new-element-button.component';
import { WidgetDashboardComponent } from './dashboard/widget-dashboard.component';
import { RowButtonsComponent } from './row-buttons/row-buttons.component';
import { GridLayoutModule } from '../grid-layout/grid-layout.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { getIconRegistry } from '../../utils/iconRegistry';
import { WidgetModule } from '../widget/widget.module';
import { IconModule } from '../icon/icon.module';

const featherIcons = [
    'x'
];

const imports = [
    CommonModule,
    GridLayoutModule,
    WidgetModule,
    IconModule,
    IconButtonModule,
    MatRippleModule
];

const components = [
    WidgetDashboardComponent
];

const innerComponents = [
    NewElementButtonComponent,
    RowButtonsComponent
];

@NgModule({
    imports,
    declarations: [
        ...components,
        ...innerComponents
    ],
    exports: components
})
export class WidgetDashboardModule {

    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
    ) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace(
            'feather',
            iconsPath
        )(featherIcons);
    }

}
