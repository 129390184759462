import { WidgetLibrary } from './select-cell-type-dialog.types';

export const WIDGET_LIBRARY: WidgetLibrary = [
    {
        name: 'Charts',
        elements: [
            {
                category: 'bar-chart',
                label: 'Bar chart',
                description: 'Simple bar chart',
                iconPath: ''
            },
            {
                category: 'bar-chart',
                label: 'Line chart',
                description: 'Simple line chart',
                iconPath: ''
            },
            {
                category: 'bar-chart',
                label: 'Pie chart',
                description: 'Simple pie chart',
                iconPath: ''
            }
        ]
    },
    {
        name: 'Indicators',
        elements: [
            {
                category: 'entity-text',
                label: 'Text properties',
                description: 'Text fields describing entity fields',
                iconPath: ''
            },
            {
                category: 'horizontal-bars',
                label: 'Numeric bars',
                description: 'Numerical indicators for value series',
                iconPath: ''
            },
            {
                category: 'logs-list',
                label: 'Activity stream',
                description: 'Display any activity stream as a list',
                iconPath: ''
            }
        ]
    }
];
