import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { getIconRegistry } from '../../../utils/iconRegistry';

const featherIcons = [
    'info',
    'alert-triangle',
    'alert-octagon'
];

export type SnackbarMessageType =
    | 'error'
    | 'warning'
    | 'info';

@Component({
    selector: 'udp-snackbar-message',
    templateUrl: './snackbar-message.component.html',
    styleUrls: ['./snackbar-message.component.scss']
})
export class SnackbarMessageComponent {
    typeToIconMap: Record<SnackbarMessageType, string> = {
        info: 'info',
        warning: 'alert-triangle',
        error: 'alert-octagon',
    };

    @Input()
    type: SnackbarMessageType = 'info';

    @Input()
    message: string;

    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
    ) {
        const iconsPath = '../assets/icons';
        const iconRegistry = getIconRegistry(matIconRegistry, domSanitizer);
        iconRegistry.registerIconsInNamespace(
            'feather',
            iconsPath
        )(featherIcons);
    }
}
