import * as _ from 'lodash/fp';
import { Component, Input } from '@angular/core';

import { BAR_CHART_DEFAULT_OPTIONS } from './bar-chart.consts';
import { BarChartOptions, BarChartSeries } from './bar-chart.types';

@Component({
    selector: 'udp-bar-chart',
    templateUrl: './bar-chart.component.html',
})
export class BarChartComponent {
    @Input()
    title?: string;

    @Input()
    series: BarChartSeries[] = [];

    @Input()
    categories: string[] = [];

    @Input()
    set options(options: BarChartOptions) {
        this._options = _.mergeAll([{}, BAR_CHART_DEFAULT_OPTIONS, options]);
    }

    get options(): BarChartOptions {
        return this._options;
    }

    private _options: BarChartOptions = BAR_CHART_DEFAULT_OPTIONS;
}
